import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import AboutPhotos from './AboutPhotos/AboutPhotos';
import AboutText from './AboutText/AboutText';
import MaterialIcon from 'material-icons-react';
import './About.css';

class About extends React.Component {

    state = {
        visible: false
    }

    visibiltyChangeHandler = (isVisible) => {
        if(!this.state.visible){
            this.setState({visible: isVisible});
        }
    }

    render() {
        const style = {
            backgroundImage: <MaterialIcon icon="format_quote" size={28} color={"#313131"} />
        };

        const wrapperClass = this.state.visible ? "AboutWrapper In" : "AboutWrapper";
        let content;
        if (this.props.reverse) {
            content = (
                <div className='wrapper-full'>
                <VisibilitySensor partialVisibility onChange={this.visibiltyChangeHandler}>
                <div className={wrapperClass} style={style}>
                    <AboutText text={this.props.text} reverse={this.props.reverse} endorsement={this.props.endorsement} />
                    <AboutPhotos>
                        <img src={this.props.ImageLarge} alt="Tom Cole" />
                        <img src={this.props.ImageSmall1} alt="Tom Cole" />
                        <img src={this.props.ImageSmall2} alt="Tom Cole" />
                    </AboutPhotos>

                </div>
                </VisibilitySensor>
                </div>
            )
        } else {
            content = (
                <div className='wrapper-full'>
                <VisibilitySensor  partialVisibility onChange={this.visibiltyChangeHandler}>
                <div className={wrapperClass}>
                    <AboutPhotos>
                        <img src={this.props.ImageLarge} alt="Tom Cole" />
                        <img src={this.props.ImageSmall1} alt="Tom Cole" />
                        <img src={this.props.ImageSmall2} alt="Tom Cole" />
                    </AboutPhotos>
                    <AboutText text={this.props.text} endorsement={this.props.endorsement} />
                </div>
                </VisibilitySensor>
                </div>
            )
        }
        return content;
    }
}

export default About;
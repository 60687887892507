import React from 'react';
import TitleText from './TitleText/TitleText';
import './Title.css';
import Tagline from '../Tagline/Tagline';
import Button from '../Button/Button';

const Title = (props) => {
    return (
        <div className="TitleContainer">
            <div className="Title">
                <div id='circle1'>
                    <div id='circle2'>
                        <TitleText />
                        <Tagline tagline={props.tagline} />
                    </div>
                </div>

                {/* <a className="Button" style={{textAlign: 'center', marginTop: '40px'}} href="#music">Listen</a> */}
                <Button id="listen" btnstyle={{ paddingTop: '2px' }} style={{ textAlign: 'center', marginTop: '20px' }} action={props.listenClickHandler}>LISTEN</Button>
            </div>
            
        </div>
    )
}

export default Title;
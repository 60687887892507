import React from 'react';
import arrow from '../../Assets/Images/chevron-down.png';
import Title from '../../Components/Title/Title';
import './Billboard.css';

const Billboard = (props) => {
    return (

        <div className="Home Page">
            <div id="triangle" class="t-right"></div>
            <div class="billboard"></div>
            <div className='wrapper' style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>

                <Title tagline={props.tagline} listenClickHandler={props.listenClickHandler} />
                <div className='arrow-down' onClick={props.listenClickHandler}>
                    <img src={arrow} />
                </div>
            </div>
        </div>

    )
}

export default Billboard;
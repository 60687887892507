import React from 'react';
import './AlbumTitle.css';

const AlbumTitle = (props) => {

    return(
        <div className="AlbumTitle">
           <h4>{props.title}</h4>
        </div>
    )

}

export default AlbumTitle;
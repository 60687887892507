import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faBandcamp, faSpotify } from '@fortawesome/free-brands-svg-icons';
import './Social.css';

const Social = props => {

    return(
        <div className={props.light ? "Social opp" : "Social"}>
            <ul>
                <li><FontAwesomeIcon onClick={() => window.open('https://www.facebook.com/TomColeMusic', '_blank')} icon={faFacebook} /></li>
                <li><FontAwesomeIcon onClick={() => window.open('https://www.instagram.com/tomcolemusic', '_blank')} icon={faInstagram} /></li>
                <li><FontAwesomeIcon onClick={() => window.open('https://www.youtube.com/user/TomColeMusic1', '_blank')} icon={faYoutube} /></li>
                <li><FontAwesomeIcon onClick={() => window.open('http://www.tomcole.bandcamp.com', '_blank')} icon={faBandcamp} /></li>
                <li><FontAwesomeIcon onClick={() => window.open('https://open.spotify.com/artist/0FGE6WwSGx7Lgf7kHbHnQ6?si=8sA5GdRqSsWw01EUgH7Cyg', '_blank')} icon={faSpotify} /></li>
            </ul>
        </div>
    )

}

export default Social;
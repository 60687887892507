import React, { Component } from 'react';
import axios from 'axios';
import Header from '../../Components/Header/Header';
import Drawer from '../../Components/Drawer/Drawer';
import Billboard from '../Billboard/Billboard';
import Music from '../Music/Music';
import Player from '../../Components/Player/Player';
import col from '../../Assets/Music/steps_on_the_way/1 Colorado Girl.mp3';
import About from '../About/About';
import Events from '../Events/Events';
import Footer from '../../Components/Footer/Footer';
import Contact from '../Contact/Contact';
import PlayerArrow from '../../Components/Player/Arrow/Arrow';
import albumcover from '../../Assets/Images/steps_on_the_way_cover.jpg';
import { API_URL } from '../../tools/api';
import Video from '../Video/Video';

class Home extends Component {

	state = {
		track: { id: 1, title: "Colorado Girl", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=000000/linkcol=0687f5/tracklist=false/artwork=small/track=4265338461/transparent=true/", path: col },
		playerUrl: "",
		showPlayer: false,
		content: {},
		contentReady: false,
		events: [],
		playing: false
	}

	componentWillMount() {
		this.getContent();
	}

	getContent = () => {
		axios({
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			},
			url: API_URL + "get",
			params: {}
		})
			.then(parsedRes => {
				if (parsedRes.status === 200 & parsedRes.data.length > 0) {
					const content = { ...parsedRes.data[0] }
					this.setState({ content: content });
					this.getEvents();
				} else {
					this.setDefaultContent();
				}
				this.checkURLPart();
			}).catch(err => {
				this.setDefaultContent();
				this.checkURLPart();
			});
	}

	getEvents = () => {
		axios({
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			},
			url: API_URL + "get_events",
			params: {}
		})
			.then(parsedRes => {
				if (parsedRes.status === 200 & parsedRes.data.length > 0) {
					const events = [...parsedRes.data];
					events.sort((a, b) => {//Sort by date
						if (a.date < b.date)
							return -1;
						if (a.date > b.date)
							return 1;
						return 0;
					});
					this.setState({ events: events, contentReady: true });
				}else{
					this.setState({ contentReady: true });
				}

			}).catch(err => {
				this.setState({ contentReady: true });
			});
	}

	setDefaultContent = () => {
		const content = {
			id: 3,
			about_text: "Tom Cole is an acoustic guitar player/singer-songwriter from Hastings, UK, making music inspired by the traditions of American folk, gospel, blues and country. Tom has been entertaining the Hastings area for the last 5 years with his blend of all things Americana, releasing three EPs of original material along the way: the hopeful 'Bound' in 2013, the protest E.P. 'Competition for My Life' in 2014, and his current E.P 'Ramblin’ Man'. \n Tom’s lucky enough to play with some of the best musicians in the local area under the guise of the Tom Cole Band, or sometimes as a duo for smaller affairs.",
			tagline: "Alt Country meets American Folk Revival",
			endorsement1: "I have raved about this singer-songwriter before and will no doubt do so again.",
			endorsement1_name: "NEIL PARTRICK - SEARCHING FOR THE OLD FOLK REBELS",
			endorsement2: "Reminiscent of some of the great acoustic balladeers of the 1960s - Drake, Buckley, Stewart etc - yet thoroughly modern, great songs and easy on the ear.",
			endorsement2_name: "FACEBOOK REVIEW",
			vid_title: 'Sure - Music Video',
			vid_url: 'https://www.youtube-nocookie.com/embed/adwr2YbL4yI'
		}
		const events = [
			{name: "Tom Cole's Sunday Sounds at the Brickmaker's January - with guest Cordelia Gartside",info_link:"https://google.com",date:1674912710,venue:"Brickmaker's Alehouse, Bexhill", time: "6:30pm"},
			{name: "Hastings Fat Tuesday - Acoustic Saturday",info_link:"https://google.com",date:1674912710,venue:"Little Mashers, Oscars,  Piper, HEIST, Collected Fictions", time: "6:30"},
			{name: "Tom Cole's Sunday Sounds at the Brickmaker's February -  with guest Kev Minney",info_link:"https://google.com",date:1674912710,venue:"Brickmaker's Alehouse, Bexhill", time: "6:30"},
			{name: "Tom Cole's Sunday Sounds at the Brickmaker's March - with guest Gabriel Moreno",info_link:"https://google.com",date:1674912710,venue:"Brickmaker's Alehouse, Bexhill", time: "6:30"}
		]
		this.setState({ content: content, contentReady: true, events:events })
	}

	checkURLPart = () => {
		const lastPart = window.location.href.split("/").pop();
		if(lastPart.startsWith('#')){
			this.navigateHandler(lastPart.replace("#",""))
		}
	}

	setPlaylist = (playlist) => {
		this.setState({ playlist: playlist });
	}

	playHandler = (track) => {
		this.setState({ track: track, showPlayer: true, playing: true });
	}

	pauseHandler = () => {
		this.setState({ playing: false });
	}

	navigateHandler = (id) => {
		try{
			document.getElementById(id).scrollIntoView();
		}catch(e){}
	}

	playerCollapseHandler = () => {
		this.setState({showPlayer: !this.state.showPlayer});
	}

	nextClickHandler = () => {
		this.musRef.next(this.state.track.id);
	}

	previousClickHandler = () => {
		this.musRef.previous(this.state.track.id);
	}


	render() {
		if (this.state.contentReady) {
			return (
				<div className="App">
					<Header navigateHandler={(id) => this.navigateHandler(id)} />
					<Drawer />
					<Billboard tagline={this.state.content.tagline} listenClickHandler={() => this.navigateHandler('music')} />
					<Music track={this.state.track} playing={this.state.playing} playHandler={this.playHandler} pauseHandler={this.pauseHandler} musicRef={ref => this.musRef = ref} vidTitle={this.state.content.vid_title} vidUrl={this.state.content.vid_url}/>
					<Events events={this.state.events} />
					<About text={this.state.content.about_text} endorsement1={this.state.content.endorsement1} endorsement1Name={this.state.content.endorsement1_name} endorsement2={this.state.content.endorsement2} endorsement2Name={this.state.content.endorsement2_name} />
					<Contact />
					<Player playing={this.state.playing} track={this.state.track} showPlayer={this.state.showPlayer} setPlayingStatus={(status) => this.setState({playing: status})} nextClickHandler={this.nextClickHandler} previousClickHandler={this.previousClickHandler}/>
					<Footer />
					<PlayerArrow showing={this.state.showPlayer} clickHandler={this.playerCollapseHandler}/>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default Home;

import React from 'react';
import './AlbumInfo.css';
import AlbumArt from './AlbumArt/AlbumArt';
import TrackListing from './TrackListing/TrackListing';
import AlbumTitle from './AlbumTitle/AlbumTitle';
import AlbumRelease from './AlbumRelease/AlbumRelease';
import FindBandCamp from '../FindBandCamp/FindBandCamp';

const AlbumInfo = (props) => {

    return (
        <div className="AlbumInfo">
            <div className='col'>
                <AlbumArt image={props.artImage} />
                <div className='col'>
                    <AlbumTitle title={props.title} />
                    <AlbumRelease release={props.release} />
                    <div id="shape1" className='shape'></div>
                </div>
            </div>
            <div className="PlayerList">

                <TrackListing trackPlaying={props.trackPlaying} playing={props.playing} tracks={props.tracks} pauseHandler={props.pauseHandler} playHandler={(url) => props.playHandler(url)} />
                <FindBandCamp />
            </div>
        </div>
    )

}

export default AlbumInfo;
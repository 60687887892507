import React from 'react';
import './TrackListing.css';
import Track from './Track';

const TrackListing = React.memo((props) => {
    const tracks = [...props.tracks]
    return (
        <div className="TrackListing">
            <ul>
                {tracks.map(track => (
                    <Track 
                        key={`${track.id}${Math.random()}`} // Ensure each track has a unique key
                        trackPlaying={props.trackPlaying} 
                        playing={props.playing} 
                        track={track} 
                        pauseHandler={props.pauseHandler} 
                        playHandler={props.playHandler} 
                    />
                ))}
            </ul>
        </div>
    );
});

export default TrackListing;

import React from 'react';
import './Player.css';
import SongInfo from './SongInfo/SongInfo';
import Controls from './Controls/Controls';
import Waveform from './Waveform/Waveform';
import Artwork from './Artwork/Artwork';

class Player extends React.Component {

    state = {
        audio: null
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.track.id !== this.props.track.id) {
            if (this.state.audio) {
                this.state.audio.pause();
            }
            const aud = new Audio(nextProps.track.path);
            this.setState(
                { audio: aud, playing: false },
                this.newTrackHandler()
            )
            aud.play();
            return;
        }

        if (nextProps.playing && !this.props.playing) {
            if (!this.state.audio) {
                const aud = new Audio(this.props.track.path);
                this.setState({ audio: aud, playing: true });
                aud.play();
            } else {
                this.state.audio.play();
            }
            this.props.setPlayingStatus(true);
        } else if (!nextProps.playing && this.props.playing) {
            if (this.state.audio) {
                this.state.audio.pause();
            }
            this.props.setPlayingStatus(false);
        }

    }

    newTrackHandler = () => {
        if (this.state.playing) {
            //this.state.audio.play();
        }
    }

    clickHandler = () => {
        if (this.props.playing) {
            this.setState({ playing: false });
            this.state.audio.pause();
            this.props.setPlayingStatus(false);
        } else {
            this.setState({ playing: true });
            this.state.audio.play();
            this.props.setPlayingStatus(true);
        }
    }

    render() {
        const display = this.props.showPlayer ? { opacity: 1 } : { opacity: 0 };
        //const arrow = this.state.playing ? "arrow_drop_down"
        return (
            <div className="Player" style={display}>
                {/* <iframe allow="autoplay"  src={this.props.track.url} seamless><a href="http://tomcole.bandcamp.com/album/ramblin-man">Ramblin&#39; Man by Tom Cole</a></iframe>  */}

                <Controls clickHandler={this.clickHandler} playing={this.props.playing} nextClickHandler={this.props.nextClickHandler} previousClickHandler={this.props.previousClickHandler} />
                {/* <Waveform track={this.props.track} /> */}
                <SongInfo track={this.props.track} />
                <Artwork track={this.props.track} />

            </div>
        )
    }
}

export default Player;
import React from 'react';
import Navigation from '../Navigation/Navigation';
import Social from '../Social/Social';

import './Header.css';

class Header extends React.Component {

    state = {
        altHeader: false,
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
    }

    scrollHandler = () => {
        const lastScrollY = window.scrollY;
        if (lastScrollY > 90) {
            this.setState({ altHeader: true });
        } else if (lastScrollY <= 90) {
            this.setState({ altHeader: false });
        }
    }

    render() {
        const headerStyle = this.state.altHeader ? "Header Alt" : "Header";
        return (
            <div className={this.props.alwaysAlt ? "Header Alt" : headerStyle}>
                <div className='wrapper'>
                    <div className='row between'>
                        <Navigation light={this.props.light} delegateNav={this.props.delegateNav} navigateHandler={(id) => this.props.navigateHandler(id)} />
                        <Social light={this.props.light}/>
                    </div>
                </div>
            </div>
        )
    }

}

export default Header;
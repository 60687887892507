import React from 'react';
import AlbumInfo from '../../Components/AlbumInfo/AlbumInfo';
import Extra from '../../Components/Extra/Extra';
import Video from '../Video/Video';
import albumcover from '../../Assets/Images/steps_on_the_way_cover.jpg';
import col from '../../Assets/Music/steps_on_the_way/1 Colorado Girl.mp3';
import two from "../../Assets/Music/steps_on_the_way/2 Don't Think Twice, It's All Right.mp3";
import three from "../../Assets/Music/steps_on_the_way/3 Hang Me, Oh Hang Me.mp3";
import four from "../../Assets/Music/steps_on_the_way/4 Kimbie.mp3";
import five from "../../Assets/Music/steps_on_the_way/5 Early Morning Rain.mp3";
import six from "../../Assets/Music/steps_on_the_way/6 Lost On The River.mp3";
import seven from "../../Assets/Music/steps_on_the_way/7 Angel In A Blue Dress.mp3";
import eight from "../../Assets/Music/steps_on_the_way/8 Between The Wars.mp3";
import nine from "../../Assets/Music/steps_on_the_way/9 I Ain't Got No Home.mp3";
import ten from "../../Assets/Music/steps_on_the_way/10 These Days.mp3";
import eleven from "../../Assets/Music/steps_on_the_way/11 Storms Are On The Ocean.mp3";
import twelve from "../../Assets/Music/steps_on_the_way/12 Pretty Mary.mp3";
import thirteen from "../../Assets/Music/steps_on_the_way/13 Tomorrow Is A Long Time.mp3";
import fourteen from "../../Assets/Music/steps_on_the_way/14 Won't You Sometimes Think Of Me.mp3";
import fifteen from "../../Assets/Music/steps_on_the_way/15 Clay Pigeons.mp3";

import './Music.css';

class Music extends React.Component {

    state = {
        albums: [
            {
                title: "Stops on the way to here",
                release: "2024",
                tracks: [
                    { id: 1, title: "Colorado Girl", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=000000/linkcol=0687f5/tracklist=false/artwork=small/track=4265338461/transparent=true/", path: col },
                    { id: 2, title: "Don't Think Twice, It's All Right", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: two },
                    { id: 3, title: "Hang Me, Oh Hang Me", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: three },
                    { id: 4, title: "Kimbie", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: four },
                    { id: 5, title: "Early Morning Rain", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: five },
                    { id: 6, title: "Lost On The River", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: six },
                    { id: 7, title: "Angel In A Blue Dress", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: seven },
                    { id: 8, title: "Between The Wars", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: eight },
                    { id: 9, title: "I Ain't Got No Home", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: nine },
                    { id: 10, title: "These Days", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: ten },
                    { id: 11, title: "Storms Are On The Ocean", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: eleven },
                    { id: 12, title: "Pretty Mary", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: twelve },
                    { id: 13, title: "Tomorrow Is A Long Time", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: thirteen },
                    { id: 14, title: "Won't You Sometimes Think Of Me", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: fourteen },
                    { id: 15, title: "Clay Pigeons", cover: albumcover, url: "https://bandcamp.com/EmbeddedPlayer/album=446681374/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/artwork=small/track=77584289/transparent=true/", path: fifteen }
                ],
                image: albumcover
            },
        ]
    }

    componentWillMount() {
        this.props.musicRef(this);
    }

    next = (id) => {
        let track;
        const arrId = id - 1;
        if (id === (this.state.albums[0].tracks.length)) {
            track = this.state.albums[0].tracks[0];
        } else {
            track = this.state.albums[0].tracks[arrId + 1];
        }
        this.props.playHandler(track)
    }

    previous = (id) => {
        let track;
        const arrId = id - 1;
        if (id === 1) {
            track = this.state.albums[0].tracks[this.state.albums[0].tracks.length-1];
        } else {
            track = this.state.albums[0].tracks[arrId - 1];
        }
        this.props.playHandler(track)
    }

    getAlbumsContent = () => {
        const albums = [...this.state.albums];
        const content = albums.map((album, index) => {
            return (
                <AlbumInfo
                    playHandler={(track) => this.props.playHandler(track)}
                    pauseHandler={this.props.pauseHandler}
                    key={index}
                    release={album.release}
                    title={album.title}
                    artImage={album.image}
                    tracks={album.tracks}
                    trackPlaying={this.props.track}
                    playing={this.props.playing} />
            )
        })

        return content;
    }

    render() {
        const albums = this.getAlbumsContent();
        return (
            <div>
                <div className="Music Page" id="music" style={{padding:"120px 0 0 0"}}>
                    <div className="wrapper">
                       
                        <div className="Album">
                            {albums}
                        </div>

                        
                    </div>
                    <Video vidTitle={this.props.vidTitle} vidUrl={this.props.vidUrl} />
                </div>
                {/* <div className="Extra Page"> */}
                    {/* <Extra /> */}
                {/* </div> */}
            </div>
        )
    }

}

export default Music;
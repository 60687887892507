import React from 'react';
import './Button.css';

const Button = (props) => {
    return(
        <div id={props.id ? props.id : "btn"} style={{...props.style}}> 
            <button style={{...props.btnstyle}} className="Button" onClick={props.action}>{props.children}</button>
        </div>
    )
}

export default Button;
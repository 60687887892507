import React from 'react';
import axios from 'axios';
import striptags from 'striptags';
import ReCAPTCHA from "react-google-recaptcha";
import { API_URL } from '../../tools/api';
import './ContactForm.css';

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    state = {
        valid: false,
        email: "",
        name: "",
        message: "",
        errFields: [],
        errorMsgs: [],
        capchaToken: null,
        canShowCapcha: false
    }

    componentDidMount() {

        if (!this.state.canShowCapcha) {
            setTimeout(() => { this.setState({ canShowCapcha: true }) }, 8000);
        }
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        this.setState({ capchaToken: recaptchaToken });
    }

    onEmailChangeHandler = (e) => {
        this.setState({ email: e.target.value });
    }

    onNameChangeHandler = (e) => {
        this.setState({ name: e.target.value });
    }

    onMessageChangeHandler = (e) => {
        this.setState({ message: e.target.value });
    }

    onSubmitHandler = (e) => {
        e.preventDefault();
        const formObj = {
            email: striptags(this.state.email),
            name: striptags(this.state.name),
            msg: striptags(this.state.message)
        };
        if (this.validate(formObj)) {
            this.setState({
                errFields: [],
                errorMsgs: []
            });
            this.submitForm(formObj);
        }
    }

    submitForm = (formObj) => {
        axios({
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            url: API_URL + "send_message",
            params: formObj
        })
            .then(parsedRes => {
                if (parsedRes.status === 200) {
                    this.setState({
                        valid: true,
                        email: "",
                        name: "",
                        message: "",
                    });
                    //this.captchaDemo.reset();
                }
            })
            .catch(err => {
                this.setState({
                    errorMsgs: ["Form could not be submitted. Please try again."]
                });
            });
    }

    validate = () => {
        let errFields = [];
        let errorMsgs = [];
        if (this.state.email === '' || this.state.email === null) {
            errFields.push('email');
            errorMsgs.push('Email field is empty');
            this.setState({
                valid: false,
                errFields: errFields,
                errorMsgs: errorMsgs,
            });
        } else {//Check for valid email
            let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (reg.test(this.state.email) === false) {
                errFields.push('email');
                errorMsgs.push('Not a valid email');
                this.setState({
                    valid: false,
                    errFields: errFields,
                    errorMsgs: errorMsgs
                });
            }
        }

        if (this.state.name === '' || this.state.name === null) {
            errFields.push('name');
            errorMsgs.push('Name field is empty');
            this.setState({
                valid: false,
                errFields: errFields,
                errorMsgs: errorMsgs
            });
        } else if (this.state.name.length > 100) {
            errFields.push('name');
            errorMsgs.push('Name is too long');
            this.setState({
                valid: false,
                errFields: errFields,
                errorMsgs: errorMsgs
            });
        }

        if (this.state.message === '' || this.state.message === null) {
            errFields.push('message');
            errorMsgs.push('Message field is empty');
            this.setState({
                valid: false,
                errFields: errFields,
                errorMsgs: errorMsgs
            });
        } else if (this.state.message.length > 400) {
            errFields.push('message');
            errorMsgs.push('Message is too long');
            this.setState({
                valid: false,
                errFields: errFields,
                errorMsgs: errorMsgs
            });
        }

        if (this.state.capchaToken === "" || this.state.capchaToken === null) {
            errFields.push('recapcha');
            errorMsgs.push('Please complete capcha form');
            this.setState({
                valid: false,
                errFields: errFields,
                errorMsgs: errorMsgs
            });
        }

        if (errFields.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        //const capcha = this.state.canShowCapcha ? <ReCaptcha ref={(el) => { this.captchaDemo = el; }} size="normal" data-theme="light" sitekey="6Ldp2JQUAAAAACX3IUJDrlwjTBpYu2EiRp0zaVDR" verifyCallback={this.verifyCallback} /> : null;
        const successMsg = this.state.valid ? <div className="Success">Thank You. Your details have been sent!</div> : null
        const errors = this.state.errorMsgs.map(err => <div className="Error">{err}</div>)
        const emailStyle = this.state.errFields.includes('email') ? { borderColor: '#ff0000', borderWidth: 2 } : null;
        const nameStyle = this.state.errFields.includes('name') ? { borderColor: '#ff0000', borderWidth: 2 } : null;
        const messageStyle = this.state.errFields.includes('message') ? { borderColor: '#ff0000', borderWidth: 2 } : null;
        return (
            <div className="Container">
                <h3>Get in touch</h3>
                <form className="ContactForm">
                    <input type="email" placeholder="Email" onChange={this.onEmailChangeHandler} style={emailStyle} value={this.state.email} />
                    <input type="name" placeholder="Name" onChange={this.onNameChangeHandler} style={nameStyle} value={this.state.name} />
                    <textarea type="message" placeholder="Message" onChange={this.onMessageChangeHandler} style={messageStyle} value={this.state.message} />
                    {/* <div className="Recapcha">
                        {capcha}
                    </div> */}
                    <ReCAPTCHA
                        sitekey="6Ldp2JQUAAAAACX3IUJDrlwjTBpYu2EiRp0zaVDR"
                        onChange={this.verifyCallback}
                    />
                    <div className="MoreInfo">
                        <button className="MoreInfo SendBtn" type="submit" placeholder="Send" onClick={this.onSubmitHandler}>Send</button>
                    </div>
                </form>
                {errors}
                {successMsg}
            </div>
        )
    }


}

export default ContactForm;

import React from 'react';
import axios from 'axios';
import MaterialIcon from 'material-icons-react';
import Cookies from 'universal-cookie';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Login from '../../Components/Login/Login';
import { API_URL } from '../../tools/api';
import './Admin.css';

class Admin extends React.Component {

    state = {
        content: {},
        showSnackbar: false,
        loggedIn: false
    }

    componentWillMount() {
        const cookies = new Cookies();
        const logged = cookies.get('logged');
        if(logged){
            this.setState({ loggedIn: true });
        }

        this.getContent();
    }

    getContent = () => {
        axios({
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: API_URL + "get",
            params: {}
        })
            .then(parsedRes => {
                if (parsedRes.status === 200 & parsedRes.data.length > 0) {
                    const content = { ...parsedRes.data[0] }
                    this.setState({ content: content });
                    this.getEvents();
                } else {

                }

            }).catch(err => {
                //this.setDefaultContent();
                console.log(err.response);
            });
    }


    getEvents = () => {
        axios({
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: API_URL + "get_events",
            params: {}
        })
            .then(parsedRes => {
                if (parsedRes.status === 200 & parsedRes.data.length > 0) {
                    const events = [...parsedRes.data];
                    const content = { ...this.state.content };
                    content.events = events;
                    this.setState({ content: content, contentReady: true });
                } else {
                    this.setState({ contentReady: true });
                }

            }).catch(err => {
                this.setState({ contentReady: true });
            });
    }

    changeHandler = (event, type) => {
        let content = { ...this.state.content };
        switch (type) {
            case "tagline":
                content.tagline = event.target.value;
                break;
            case "vid_title":
                content.vid_title = event.target.value;
                break;
            case "vid_url":
                content.vid_url = event.target.value;
                break;
            case "about":
                content.about_text = event.target.value;
                break;
            case "endorsement1":
                content.endorsement1 = event.target.value;
                break;
            case "endorsement1_name":
                content.endorsement1_name = event.target.value;
                break;
            case "endorsement2":
                content.endorsement2 = event.target.value;
                break;
            case "endorsement2_name":
                content.endorsement2_name = event.target.value;
                break;

        }
        this.setState({ content: content });
    }

    changeEventHandler = (index, event, type) => {
        const content = { ...this.state.content };
        const events = [...content.events];
        const selectedEvent = events[index];
        switch (type) {
            case "name":
                selectedEvent.name = event.target.value;
                break;
            case "venue":
                selectedEvent.venue = event.target.value;
                break;
            case "description":
                selectedEvent.description = event.target.value;
                break;
            case "date":
                selectedEvent.date = String(this.toTimestamp(event));
                break;
            case "time":
                selectedEvent.time = event.target.value;
                break;
            case "info_link":
                selectedEvent.info_link = event.target.value;
                break;
        }
        content.events = events;
        this.setState({ content: content });
    }

    saveData = () => {
        const params = { content: this.state.content };
        axios({
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: API_URL + "update_content",
            params: params
        })
            .then(parsedRes => {
                if (parsedRes.status === 200 & parsedRes.data) {
                    Alert.success('Content updated successfully!', {
                        position: 'top-right',
                        effect: 'slide',
                        timeout: 5000,
                        offset: 10
                    });
                } else {
                    Alert.error('That failed. Show Keith this: some bad shit happened!', {
                        position: 'top-right',
                        effect: 'slide',
                        timeout: 5000,
                        offset: 10
                    });
                }

            }).catch(err => {
                Alert.error('That failed. Show Keith this: ' + err, {
                    position: 'top-right',
                    effect: 'slide',
                    timeout: 5000,
                    offset: 10
                });
            });
    }

    deleteEvent = (index) => {
        const content = { ...this.state.content };
        const events = [...content.events];
        events.splice(index, 1);
        content.events = events;
        this.setState({ content: content });
    }

    addEvent = () => {
        const content = { ...this.state.content };
        const events = [...content.events];
        events.push({ id: "", name: "", venue: "", description: "", date: this.toTimestamp(new Date()), time: "", info_link: "" });
        content.events = events;
        this.setState({ content: content });
    }

    toTimestamp = (strDate) => {
        var datum = Date.parse(strDate);
        return datum / 1000;
    }

    getReadableDate = (timestamp) => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const date = new Date(timestamp * 1000);
        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return day + " " + month + " " + year;
    }

    loginHandler = () => {
        this.setState({ loggedIn: true });
        const cookies = new Cookies();
        cookies.set('logged', true, { path: '/' });
    }

    _renderEvents = () => {
        if (this.state.content && this.state.content.events) {
            const events = this.state.content.events.map((event, index) => {
                const date = this.getReadableDate(event.date);
                return (
                    <div key={index} className="EventRow">
                        <div className="EventBlock">
                            <p>Event Name</p>
                            <input className="InputText" type="text" value={event.name} onChange={(event) => this.changeEventHandler(index, event, 'name')} />
                        </div>
                        <div className="EventBlock">
                            <p>Venue Name/Address</p>
                            <input className="InputText" type="text" value={event.venue} onChange={(event) => this.changeEventHandler(index, event, 'venue')} />

                        </div>
                        <div className="EventBlock">
                            <p>Description</p>
                            <input className="InputText" type="text" value={event.description} onChange={(event) => this.changeEventHandler(index, event, 'description')} />
                        </div>
                        <div className="EventBlock">
                            <p>Date</p>
                            <DatePicker selected={new Date(event.date * 1000)} onChange={(event) => this.changeEventHandler(index, event, 'date')} />
                        </div>
                        <div className="EventBlock">
                            <p>Time</p>
                            <input className="InputText" type="text" value={event.time} onChange={(event) => this.changeEventHandler(index, event, 'time')} />
                        </div>
                        <div className="EventBlock">
                            <p>More Info Link</p>
                            <input className="InputText" type="text" value={event.info_link} onChange={(event) => this.changeEventHandler(index, event, 'info_link')} />
                        </div>
                        <div className="DeleteBtn" onClick={() => this.deleteEvent(index)}><MaterialIcon icon="delete" size={24} color={"#fff"} /></div>
                    </div >
                )
            });
            return events;
        } else {
            return null;
        }
    }

    _renderAdminPanel = () => {
        return (
            <div>
                <div className="Logo"><h1 className="AdminLogo">Tom Cole</h1></div>
                <div className="Admin Page">
                    <div className="Container">
                        <h3>Content</h3>

                        <div className="InputWrapper">
                            <h4>Tagline</h4>
                            <input className="InputText" type="text" name="tag" value={this.state.content.tagline} onChange={(event) => this.changeHandler(event, 'tagline')} />
                        </div>

                        <div className="InputWrapper">
                            <h4>Video Title</h4>
                            <input className="InputText" type="text" name="tag" value={this.state.content.vid_title} onChange={(event) => this.changeHandler(event, 'vid_title')} />
                        </div>

                        <div className="InputWrapper">
                            <h4>Video Url</h4>
                            <input className="InputText" type="text" name="tag" value={this.state.content.vid_url} onChange={(event) => this.changeHandler(event, 'vid_url')} />
                        </div>

                        <div className="InputWrapper">
                            <h4>About Text</h4>
                            <textarea className="TextArea" onChange={(event) => this.changeHandler(event, 'about')} value={this.state.content.about_text}></textarea>
                        </div>

                        <div className="InputWrapper">
                            <h4>Endorsement 1</h4>
                            <textarea className="TextArea" onChange={(event) => this.changeHandler(event, 'endorsement1')} value={this.state.content.endorsement1}></textarea>
                        </div>

                        <div className="InputWrapper">
                            <h4>Endorsement 1 Name</h4>
                            <input className="InputText" type="text" name="tag" onChange={(event) => this.changeHandler(event, 'endorsement1_name')} value={this.state.content.endorsement1_name} />
                        </div>


                        <div className="InputWrapper">
                            <h4>Endorsement 2</h4>
                            <textarea className="TextArea" onChange={(event) => this.changeHandler(event, 'endorsement2')} value={this.state.content.endorsement2}></textarea>
                        </div>

                        <div className="InputWrapper">
                            <h4>Endorsement 2 Name</h4>
                            <input className="InputText" type="text" name="tag" onChange={(event) => this.changeHandler(event, 'endorsement2_name')} value={this.state.content.endorsement2_name} />
                        </div>


                    </div>

                </div>

                <div className="AdminEvents Page">
                    <div className="Container">
                        <h3>Events</h3>
                        <div className="EventWrapper">

                            {this._renderEvents()}
                            <div className="EventRow Add" onClick={this.addEvent}>
                                <MaterialIcon icon="add_circle_outline" size={96} color={"#fff"} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Save" onClick={this.saveData}>
                    <MaterialIcon icon="save" size={36} color={"#fff"} />
                </div>

                <Alert stack={{ limit: 3 }} />
            </div>
        )
    }

    render() {
        
        const content = this.state.loggedIn ? this._renderAdminPanel() : <Login loginHandler={this.loginHandler} />
        return content;
    }

}

export default Admin;
import React from 'react';
import axios from 'axios';
import './Login.css';
import { API_URL } from '../../tools/api';

class Login extends React.PureComponent {

    state = {
        user: "",
        password: "",
        fail: false,
        failText: ""
    }

    changeHandler = (event, type) => {
        if(type === "user"){
            this.setState({user: event.target.value});
        }else{
            this.setState({pass: event.target.value});
        }
    }

    attemptLogin = () => {
        axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            url: API_URL+"login",
            params: {username: this.state.user, pass: this.state.pass}
        })
            .then(parsedRes => {
                if(parsedRes.data == true){
                    this.props.loginHandler()
                }else{
                    this.setState({fail: true, failText: "Login failed!"});
                }
            }).catch(err => {
                this.setState({fail: true, failText: "Error occured. Tell Keith this: "+err})
            });
    }


    render() {
        const error = this.state.fail ? <p className="LoginErr">{this.state.failText}</p> : null;
        return (
            <div className="LoginContainer">
                <div className="Logo"><h1 className="AdminLogo">Tom Cole</h1></div>
                <input type="text" placeholder="Username" onChange={(event) => this.changeHandler(event,"user")}/>
                <input type="password" placeholder="Password" onChange={(event) => this.changeHandler(event,"pass")}/>
                <button onClick={this.attemptLogin}>LOGIN</button>
                {error}
            </div>
        )
    }

}

export default Login;